import React from 'react';
import { Link } from "gatsby";
import "./BlogGridItem.scss";

function BlogGridItem({ node, title }){

    return (
        <article className="bloggriditem-component col-lg-4 col-md-6 col-sm-12 mb-5">
            <div className="single-blog mt-30">
                <div className="blog-image">
                    <a href={node.fields.slug}>
                    <img src={ node.frontmatter.thumbnail } alt="news" />
                    {/* <img src={_newsImg} alt="news" /> */}
                     {/* // TODO: TO BE REPLACED WITH: { node.frontmatter.thumbnail } */}
                    </a>
                </div>
                <div className="blog-content">
                    <h4 className="blog-title text-center text-md-left">
                    <small className="blog-date">{node.frontmatter.date}</small>
                    <br />
                    <Link style={{ boxShadow: `none` }} to={node.fields.slug}>
                        {title}
                    </Link>
                    </h4>
                    <div className="blog-author d-flex align-items-center mb-3">
                        <div className="author-content media-body pl-0">
                            {/* <h6 className="sub-title">Posted by <span className="font-weight-bold">{ node.frontmatter.author }</span></h6> */}
                            <p className="text" dangerouslySetInnerHTML={{ __html: node.frontmatter.description || node.excerpt }}></p>
                        </div>
                    </div>
                    <Link className="btn btn-primary btn-sm" style={{ boxShadow: `none` }} to={node.fields.slug}>
                        READ MORE
                    </Link>
                </div>
            </div>
      </article>
    );

}

export default BlogGridItem;